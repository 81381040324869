
import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

import planingImg1 from "./Acets/spruha_inner[1] 4.jpg"
// "../Component/Acets/spruha_inner[1] 4.jpg"
import planingImg2 from "./Acets/spruha_inner[1] 6.jpg"
import planingImg3 from "./Acets/spruha_inner[1] 5.jpg"

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import './styles.css';

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

const BusinessGoalSlider = () => {
    return (
        <>
            <Swiper
                loop={true}
                spaceBetween={30}
                centeredSlides={true}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                pagination={{
                    clickable: true,
                }}
                navigation={true}
                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper"
            >
                <SwiperSlide>
                    <img src={planingImg1} className='w-full lg:w-auto brightness-75 hover:brightness-100 duration-100' alt="" />
                </SwiperSlide>
                <SwiperSlide>
                    <img src={planingImg2} className='w-full lg:w-auto brightness-75 hover:brightness-100 duration-100' alt="" />
                </SwiperSlide>
                <SwiperSlide>
                    <img src={planingImg3} className='w-full lg:w-auto brightness-75 hover:brightness-100 duration-100' alt="" />
                </SwiperSlide>
            </Swiper>
        </>
    )
}

export default BusinessGoalSlider
